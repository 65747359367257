import React, { useState, useEffect, useRef } from 'react';

const CodeAnimation = () => {
  const [lines, setLines] = useState([]);
  const [currentLine, setCurrentLine] = useState('');
  const [lineIndex, setLineIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const containerRef = useRef(null);

  const commands = [
    'pip install openai torch transformers',
    'Installing essential AI libraries...',
    'Installation complete!',
    'python -m openai --help',
    'Accessing OpenAI CLI help...',
    'python -c "import torch; print(torch.__version__)"',
    'PyTorch version: 2.0.1',
    'python -c "import transformers; print(transformers.__version__)"',
    'Transformers version: 4.25.1',
    'AI environment is ready!',
];

  useEffect(() => {
    const typeChar = () => {
      if (lineIndex < commands.length) {
        if (charIndex < commands[lineIndex].length) {
          setCurrentLine(prev => prev + commands[lineIndex][charIndex]);
          setCharIndex(prev => prev + 1);
        } else {
          setLines(prev => [...prev, currentLine]);
          setCurrentLine('');
          setLineIndex(prev => prev + 1);
          setCharIndex(0);
        }
      } else {
        // Reset after a longer pause
        setTimeout(() => {
          setLines([]);
          setCurrentLine('');
          setLineIndex(0);
          setCharIndex(0);
        }, 3000);
      }
    };

    const timer = setTimeout(typeChar, 50);
    return () => clearTimeout(timer);
  }, [charIndex, lineIndex, currentLine, /* commands */]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [lines, currentLine]);

  return (
    <div ref={containerRef} className="bg-black text-bluegrey p-4 font-mono text-sm leading-relaxed whitespace-pre-wrap h-64 overflow-y-auto">
      {lines.map((line, index) => (
        <div key={index}>$ {line}</div>
      ))}
      {currentLine && <div>$ {currentLine}</div>}
    </div>
  );
};

export default CodeAnimation;