import React from 'react';
import { motion } from 'framer-motion';
import Section from './Section';
import { BrainCircuit, Cpu, BarChart2, Command, Settings, Zap, Code, Terminal, Database, Layers, Box, Activity } from 'lucide-react';
import { useLanguage, translations } from './LanguageContext';

const ExpertiseCard = ({ Icon, title, desc }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="border border-silvergray p-4 hover:shadow-lg transition duration-300"
  >
    <div className="flex items-center mb-2">
      <Icon className="text-bluegrey-dark mr-2" size={20} />
      <h3 className="font-light text-base sm:text-lg tracking-wider">{title}</h3>
    </div>
    <p className="text-gray-700 font-light text-xs sm:text-sm">{desc}</p>
  </motion.div>
);

const AIExpertise = () => {
  const { language } = useLanguage();
  const t = translations[language];

  const aiExpertiseItems = [
    { icon: BrainCircuit, title: t.aiSolutions, desc: t.aiSolutionsDesc },
    { icon: Cpu, title: t.intelligentAutomation, desc: t.intelligentAutomationDesc },
    { icon: Zap, title: t.aiStrategyIntegration, desc: t.aiStrategyIntegrationDesc },
    { icon: BarChart2, title: t.advancedDataAnalytics, desc: t.advancedDataAnalyticsDesc },
    { icon: Command, title: t.promptEngineering, desc: t.promptEngineeringDesc },
    { icon: Settings, title: t.aiDrivenProcessOptimization, desc: t.aiDrivenProcessOptimizationDesc }
  ];

  const softwareSkillsItems = [
    { icon: Code, title: t.programmingScripting, desc: t.programmingScriptingDesc },
    { icon: Terminal, title: t.developmentTools, desc: t.developmentToolsDesc },
    { icon: Database, title: t.databaseSystems, desc: t.databaseSystemsDesc },
    { icon: Layers, title: t.caeCfdFemSoftware, desc: t.caeCfdFemSoftwareDesc },
    { icon: Box, title: t.cadSoftware, desc: t.cadSoftwareDesc },
    { icon: Activity, title: t.mathAnalysisTools, desc: t.mathAnalysisToolsDesc }
  ];

  return (
    <>
      <Section id="expertise" title={t.aiExpertiseTitle}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {aiExpertiseItems.map((item, index) => (
            <ExpertiseCard key={index} Icon={item.icon} title={item.title} desc={item.desc} />
          ))}
        </div>
      </Section>

      <Section id="software-skills" title={t.softwareSkillsTitle}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {softwareSkillsItems.map((item, index) => (
            <ExpertiseCard key={index} Icon={item.icon} title={item.title} desc={item.desc} />
          ))}
        </div>
      </Section>
    </>
  );
};

export default AIExpertise;