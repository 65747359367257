import { useLanguage, translations } from './LanguageContext';

const useBlogArticles = () => {
  const { language } = useLanguage();
  const t = translations[language].articles;

  const blogArticles = [
    {
      id: 1,
      title: t[1].title,
      summary: t[1].summary,
      content: t[1].content
    },
    {
      id: 2,
      title: t[2].title,
      summary: t[2].summary,
      content: t[2].content
    },
    {
      id: 3,
      title: t[3].title,
      summary: t[3].summary,
      content: t[3].content
    },
    {
      id: 'ai-game',
      title: t['ai-game'].title,
      summary: t['ai-game'].summary,
      content: t['ai-game'].content
    },
  ];

  return blogArticles;
};

export default useBlogArticles;