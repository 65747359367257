import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export const translations = {
  en: {
    // English translations
    //Hero
    heroTitle: "Revolutionizing Businesses through Advanced AI",
    heroSubtitle: "Transforming businesses with cutting-edge AI and sophisticated automation, tailored to your unique challenges",
    heroButton: "Discover Your Revolution",
    //About
    aboutTitle: "About Alexander Warth",
    aboutParagraph1: "With a powerful blend of expertise in Applied Physics (M.Sc.) and General Mechanical Engineering (B.Eng.), coupled with extensive experience in AI and software development, I stand at the forefront of technological innovation. My background at the prestigious GSI Helmholtzzentrum für Schwerionenforschung GmbH, combined with a diverse skill set spanning from advanced software architecture to precision engineering, allows me to offer unique, transformative solutions to my clients.",
    aboutParagraph2: "My mission is to help businesses harness the full potential of AI and automation, reshaping processes, dramatically boosting productivity, and driving unprecedented innovation. Whether you aim to revolutionize your workflow, transform your products, or completely reinvent your business model, I provide the expertise to guide you through a technological evolution tailored to your specific needs.",
    aiIntegrationTitle: "AI Integration",
    aiIntegrationDesc: "Implementing revolutionary AI solutions to redefine decision-making, predict trends, and optimize operations with precision and insight.",
    workflowAutomationTitle: "Workflow Automation",
    workflowAutomationDesc: "Designing and deploying cutting-edge automation that eliminates inefficiencies and propels productivity to new heights.",
    techConsultationTitle: "Technology Consultation",
    techConsultationDesc: "Providing expert guidance on adopting and integrating game-changing technologies that will set you apart in your industry.",
    // AIExpertise
    aiExpertiseTitle: "AI Expertise",
    aiSolutions: "AI Solutions",
    aiSolutionsDesc: "Custom AI models and systems that redefine industry standards while ensuring practical, reliable applications.",
    intelligentAutomation: "Intelligent Automation",
    intelligentAutomationDesc: "Creating hyper-efficient, automated workflows that eliminate errors and maximize productivity across various industries.",
    aiStrategyIntegration: "AI Strategy Integration",
    aiStrategyIntegrationDesc: "Tailored AI and automation strategies that align with specific business goals and industry requirements.",
    advancedDataAnalytics: "Advanced Data Analytics",
    advancedDataAnalyticsDesc: "Implementing state-of-the-art analytics tools for unprecedented data interpretation and strategic decision support.",
    promptEngineering: "Prompt Engineering",
    promptEngineeringDesc: "Crafting sophisticated prompts to optimize AI model performance and enhance human-AI interactions across platforms.",
    aiDrivenProcessOptimization: "AI-Driven Process Optimization",
    aiDrivenProcessOptimizationDesc: "Leveraging AI to transform industrial processes, significantly improving efficiency and reducing operational costs.",
    
    softwareSkillsTitle: "Development, Engineering, and Simulation Skills",
    programmingScripting: "Programming & Scripting",
    programmingScriptingDesc: "Proficient in Python, C++, SQL, LaTeX, VBA, and Bash enabling robust software development and automation.",
    developmentTools: "Development Tools",
    developmentToolsDesc: "Experienced with Docker, Podman, Vagrant, and Git for containerization, virtualization, and version control.",
    databaseSystems: "Database Systems",
    databaseSystemsDesc: "Skilled in MS Access and PostgreSQL for creating, managing, and optimizing databases.",
    caeCfdFemSoftware: "CAE/CFD/FEM Software",
    caeCfdFemSoftwareDesc: "Expert in COMSOL, ANSYS, and OpenFOAM for conducting complex simulations and engineering analyses.",
    cadSoftware: "CAD Software",
    cadSoftwareDesc: "Proficient in CATIA, Inventor, Eagle, and KiCad for designing intricate mechanical and electronic systems.",
    mathAnalysisTools: "Mathematics & Analysis Tools",
    mathAnalysisToolsDesc: "Advanced user of Matlab/Octave, Maple, Mathematica, and Origin for mathematical modeling and data analysis.",

    // Experience
    professionalJourney: "Professional Journey",
    aiDataScienceConsultant: "AI and Data Science Consultant - GSI Darmstadt",
    aiDataScienceConsultantPeriod: "2017 - Present",
    aiDataScienceConsultantDesc: "Driving innovation in AI and data science across complex engineering projects. Expertise in machine learning, data processing, and automation for high-performance computing environments.",
    projectLead: "Project Lead: 'Improvement of Series Test Facility Software and Hardware Infrastructure'",
    projectLeadPoint1: "Led the restructuring of software repositories and optimization of complex engineering software.",
    projectLeadPoint2: "Resolved critical hardware issues to ensure seamless project execution.",
    projectLeadPoint3: "Implemented collaborative platforms (e.g., Git, Nextcloud) to enhance communication and efficiency.",
    aiDrivenAnalysis: "AI-Driven Data Analysis and Automation:",
    aiDrivenAnalysisPoint1: "Developed and deployed Python-based automation for measurement systems, enhancing data accuracy and processing efficiency.",
    aiDrivenAnalysisPoint2: "Designed and implemented machine learning models for predictive maintenance, reducing downtime and improving system reliability.",
    aiDrivenAnalysisPoint3: "Utilized high-performance computing clusters for advanced data analysis and simulation.",
    databaseDevelopment: "Database Development and Management:",
    databaseDevelopmentPoint1: "Architected a cross-project database for Non-Conformity Reports using MS Access, integrating it with CERN EDMS for streamlined data management.",
    databaseDevelopmentPoint2: "Designed intuitive user interfaces and robust back-end systems for multi-dimensional data handling.",
    databaseDevelopmentPoint3: "Implemented automated data import/export functionalities to ensure seamless integration with existing systems.",
    advancedMeasurement: "Advanced Measurement Systems:",
    advancedMeasurementPoint1: "Engineered contactless measurement systems using capacitive sensors and laser trackers for precise magnet aperture geometry assessments.",
    advancedMeasurementPoint2: "Developed custom software solutions in Octave/Python for system control, adapted for HPC Virgo Cluster deployment.",
    advancedMeasurementPoint3: "Led the development and deployment of cryogenic measurement systems, integrating cutting-edge sensor technologies with custom software.",
    projectManagement: "Project Management and Leadership:",
    projectManagementPoint1: "Led the development and implementation of project plans for complex engineering projects, ensuring alignment with organizational goals.",
    projectManagementPoint2: "Coordinated software and hardware development teams, optimizing workflows and improving communication through structured task management.",
    projectManagementPoint3: "Managed resource allocation and timelines across multiple projects, effectively mitigating risks and ensuring successful project delivery.",
    mastersThesis: "Master's Thesis - GSI Darmstadt",
    mastersThesisPeriod: "2016",
    mastersThesisDesc: "Conducted advanced research on 'Pretreatment Procedures for Minimizing Ion Beam Induced Desorption', developing custom analysis tools and managing complex experiments.",
    bachelorsThesis: "Bachelor's Thesis - GSI Darmstadt",
    bachelorsThesisPeriod: "2013",
    bachelorsThesisDesc: "Focused on 'Analysis of the Thermodynamic Behavior of a Pump Cold-Warm Transition', involving advanced simulations and data analysis that laid the foundation for expertise in scientific computing and automation.",

    // Contact
    connectWithMe: "Connect with Me",
    connectDesc: "Ready to transform your business with AI and automation? Let's discuss how we can implement cutting-edge technologies to revolutionize your operations and set new benchmarks in your field.",

    // Impressum
    impressum: "Impressum",
    contactInformation: "Contact Information",
    legalInformation: "Legal Information",
    responsibleForContent: "Responsible for Content",
    impressumCompliance: "This Impressum complies with German law requirements for website legal notices.",

    latestInsights: "Latest Insights",
    readMore: "Read More",
    aiIntegration: "AI Integration",
    articles: {
      1: {
        title: "The Role of AI in Modern Engineering",
        summary: "A deep dive into how AI is transforming the landscape of engineering, making processes more efficient and precise.",
        content: "Full article content goes here... This would be a longer text explaining in detail how AI is being used in modern engineering practices, with examples and insights."
      },
      2: {
        title: "Optimizing Industrial Processes through AI",
        summary: "Explore real-world examples of AI applications that are revolutionizing traditional industrial workflows.",
        content: "Full article content goes here... This would include case studies and detailed explanations of how AI is optimizing various industrial processes."
      },
      3: {
        title: "The Future of AI in Business",
        summary: "Discover how AI is shaping the future of business and what it means for companies across various industries.",
        content: "Full article content goes here... This would discuss emerging AI trends, potential impacts on different sectors, and how businesses can prepare for an AI-driven future."
      },
      'ai-game': {
        title: "Interactive AI-Generated Game",
        summary: "Experience AI in action with our dynamic, input-driven game.",
        content: ""
      }
    }
  },
  de: {
    // German translations
    //Hero
    heroTitle: "Revolutionierung von Unternehmen durch fortschrittliche KI",
    heroSubtitle: "Transformation von Unternehmen mit modernster KI und ausgefeilter Automatisierung, maßgeschneidert für Ihre einzigartigen Herausforderungen",
    heroButton: "Entdecken Sie Ihre Revolution",
    //About
    aboutTitle: "Über Alexander Warth",
    aboutParagraph1: "Mit einer starken Mischung aus Expertise in Angewandter Physik (M.Sc.) und Allgemeinem Maschinenbau (B.Eng.), gepaart mit umfassender Erfahrung in KI und Softwareentwicklung, stehe ich an der Spitze der technologischen Innovation. Mein Hintergrund am renommierten GSI Helmholtzzentrum für Schwerionenforschung GmbH, kombiniert mit einem vielseitigen Kompetenzspektrum von fortschrittlicher Softwarearchitektur bis hin zu Präzisionstechnik, ermöglicht es mir, meinen Kunden einzigartige, transformative Lösungen anzubieten.",
    aboutParagraph2: "Meine Mission ist es, Unternehmen dabei zu unterstützen, das volle Potenzial von KI und Automatisierung auszuschöpfen, Prozesse neu zu gestalten, die Produktivität dramatisch zu steigern und beispiellose Innovationen voranzutreiben. Ob Sie Ihren Workflow revolutionieren, Ihre Produkte transformieren oder Ihr Geschäftsmodell komplett neu erfinden wollen - ich biete die Expertise, um Sie durch eine technologische Evolution zu führen, die auf Ihre spezifischen Bedürfnisse zugeschnitten ist.",
    aiIntegrationTitle: "KI-Integration",
    aiIntegrationDesc: "Implementierung revolutionärer KI-Lösungen zur Neudefinition der Entscheidungsfindung, Vorhersage von Trends und Optimierung von Abläufen mit Präzision und Einsicht.",
    workflowAutomationTitle: "Workflow-Automatisierung",
    workflowAutomationDesc: "Entwicklung und Einsatz modernster Automatisierung, die Ineffizienzen beseitigt und die Produktivität auf ein neues Niveau hebt.",
    techConsultationTitle: "Technologieberatung",
    techConsultationDesc: "Bereitstellung von Expertenberatung zur Einführung und Integration bahnbrechender Technologien, die Sie in Ihrer Branche abheben werden.",
    // AIExpertise
    aiExpertiseTitle: "KI-Expertise",
    aiSolutions: "KI-Lösungen",
    aiSolutionsDesc: "Maßgeschneiderte KI-Modelle und -Systeme, die Branchenstandards neu definieren und gleichzeitig praktische, zuverlässige Anwendungen gewährleisten.",
    intelligentAutomation: "Intelligente Automatisierung",
    intelligentAutomationDesc: "Erstellung hocheffizienter, automatisierter Workflows, die Fehler eliminieren und die Produktivität in verschiedenen Branchen maximieren.",
    aiStrategyIntegration: "KI-Strategieintegration",
    aiStrategyIntegrationDesc: "Maßgeschneiderte KI- und Automatisierungsstrategien, die auf spezifische Geschäftsziele und Branchenanforderungen abgestimmt sind.",
    advancedDataAnalytics: "Erweiterte Datenanalyse",
    advancedDataAnalyticsDesc: "Implementierung modernster Analysetools für beispiellose Dateninterpretation und strategische Entscheidungsunterstützung.",
    promptEngineering: "Prompt-Engineering",
    promptEngineeringDesc: "Erstellung ausgeklügelter Prompts zur Optimierung der KI-Modellleistung und Verbesserung der Mensch-KI-Interaktionen auf verschiedenen Plattformen.",
    aiDrivenProcessOptimization: "KI-gesteuerte Prozessoptimierung",
    aiDrivenProcessOptimizationDesc: "Nutzung von KI zur Transformation industrieller Prozesse, signifikante Verbesserung der Effizienz und Reduzierung der Betriebskosten.",
    
    softwareSkillsTitle: "Entwicklungs-, Ingenieur- und Simulationsfähigkeiten",
    programmingScripting: "Programmierung & Scripting",
    programmingScriptingDesc: "Versiert in Python, C++, SQL, LaTeX, VBA und Bash für robuste Softwareentwicklung und Automatisierung.",
    developmentTools: "Entwicklungstools",
    developmentToolsDesc: "Erfahrung mit Docker, Podman, Vagrant und Git für Containerisierung, Virtualisierung und Versionskontrolle.",
    databaseSystems: "Datenbanksysteme",
    databaseSystemsDesc: "Erfahren in MS Access und PostgreSQL für die Erstellung, Verwaltung und Optimierung von Datenbanken.",
    caeCfdFemSoftware: "CAE/CFD/FEM-Software",
    caeCfdFemSoftwareDesc: "Experte für COMSOL, ANSYS und OpenFOAM zur Durchführung komplexer Simulationen und technischer Analysen.",
    cadSoftware: "CAD-Software",
    cadSoftwareDesc: "Versiert in CATIA, Inventor, Eagle und KiCad für die Gestaltung komplexer mechanischer und elektronischer Systeme.",
    mathAnalysisTools: "Mathematik- & Analysewerkzeuge",
    mathAnalysisToolsDesc: "Fortgeschrittener Benutzer von Matlab/Octave, Maple, Mathematica und Origin für mathematische Modellierung und Datenanalyse.",

    // Experience
    professionalJourney: "Beruflicher Werdegang",
    aiDataScienceConsultant: "KI- und Data Science-Berater - GSI Darmstadt",
    aiDataScienceConsultantPeriod: "2017 - Gegenwart",
    aiDataScienceConsultantDesc: "Förderung von Innovationen in KI und Data Science in komplexen Ingenieurprojekten. Expertise in maschinellem Lernen, Datenverarbeitung und Automatisierung für Hochleistungsrechnerumgebungen.",
    projectLead: "Projektleitung: 'Verbesserung der Software- und Hardware-Infrastruktur der Serientesteinrichtung'",
    projectLeadPoint1: "Leitete die Umstrukturierung von Software-Repositories und die Optimierung komplexer Engineering-Software.",
    projectLeadPoint2: "Löste kritische Hardware-Probleme, um eine reibungslose Projektdurchführung zu gewährleisten.",
    projectLeadPoint3: "Implementierte kollaborative Plattformen (z.B. Git, Nextcloud) zur Verbesserung der Kommunikation und Effizienz.",
    aiDrivenAnalysis: "KI-gesteuerte Datenanalyse und Automatisierung:",
    aiDrivenAnalysisPoint1: "Entwickelte und implementierte Python-basierte Automatisierung für Messsysteme, verbesserte die Datengenauigkeit und Verarbeitungseffizienz.",
    aiDrivenAnalysisPoint2: "Entwarf und implementierte Machine-Learning-Modelle für vorausschauende Wartung, reduzierte Ausfallzeiten und verbesserte die Systemzuverlässigkeit.",
    aiDrivenAnalysisPoint3: "Nutzte Hochleistungs-Rechnercluster für fortgeschrittene Datenanalyse und Simulation.",
    databaseDevelopment: "Datenbankentwicklung und -management:",
    databaseDevelopmentPoint1: "Entwickelte eine projektübergreifende Datenbank für Nichtkonformitätsberichte mit MS Access und integrierte sie in CERN EDMS für optimiertes Datenmanagement.",
    databaseDevelopmentPoint2: "Entwarf intuitive Benutzeroberflächen und robuste Backend-Systeme für mehrdimensionale Datenverarbeitung.",
    databaseDevelopmentPoint3: "Implementierte automatisierte Datenimport/-export-Funktionen zur nahtlosen Integration mit bestehenden Systemen.",
    advancedMeasurement: "Fortschrittliche Messsysteme:",
    advancedMeasurementPoint1: "Entwickelte berührungslose Messsysteme mit kapazitiven Sensoren und Laser-Trackern für präzise Magnetapertur-Geometriebestimmungen.",
    advancedMeasurementPoint2: "Entwickelte maßgeschneiderte Softwarelösungen in Octave/Python zur Systemsteuerung, angepasst für den Einsatz im HPC Virgo Cluster.",
    advancedMeasurementPoint3: "Leitete die Entwicklung und den Einsatz kryogener Messsysteme, integrierte modernste Sensortechnologien mit kundenspezifischer Software.",
    projectManagement: "Projektmanagement und Führung:",
    projectManagementPoint1: "Leitete die Entwicklung und Umsetzung von Projektplänen für komplexe Ingenieurprojekte und stellte die Ausrichtung an organisatorischen Zielen sicher.",
    projectManagementPoint2: "Koordinierte Software- und Hardware-Entwicklungsteams, optimierte Arbeitsabläufe und verbesserte die Kommunikation durch strukturiertes Aufgabenmanagement.",
    projectManagementPoint3: "Verwaltete Ressourcenzuweisung und Zeitpläne für mehrere Projekte, milderte Risiken effektiv und gewährleistete erfolgreiche Projektabwicklung.",
    mastersThesis: "Masterarbeit - GSI Darmstadt",
    mastersThesisPeriod: "2016",
    mastersThesisDesc: "Führte fortgeschrittene Forschung zu 'Vorbehandlungsverfahren zur Minimierung der ionenstrahlinduzierten Desorption' durch, entwickelte maßgeschneiderte Analysewerkzeuge und verwaltete komplexe Experimente.",
    bachelorsThesis: "Bachelorarbeit - GSI Darmstadt",
    bachelorsThesisPeriod: "2013",
    bachelorsThesisDesc: "Konzentrierte sich auf die 'Analyse des thermodynamischen Verhaltens eines Pumpen-Kalt-Warm-Übergangs', einschließlich fortgeschrittener Simulationen und Datenanalysen, die den Grundstein für Expertise in wissenschaftlichem Rechnen und Automatisierung legten.",

    // Contact
    connectWithMe: "Kontaktieren Sie mich",
    connectDesc: "Bereit, Ihr Unternehmen mit KI und Automatisierung zu transformieren? Lassen Sie uns besprechen, wie wir modernste Technologien implementieren können, um Ihre Abläufe zu revolutionieren und neue Maßstäbe in Ihrem Bereich zu setzen.",

    // Impressum
    impressum: "Impressum",
    contactInformation: "Kontaktinformationen",
    legalInformation: "Rechtliche Informationen",
    responsibleForContent: "Verantwortlich für den Inhalt",
    impressumCompliance: "Dieses Impressum entspricht den gesetzlichen Anforderungen für rechtliche Hinweise auf Websites in Deutschland.",
    
    // Insights
    latestInsights: "Neueste Erkenntnisse",
    readMore: "Weiterlesen",
    aiIntegration: "KI-Integration",
    articles: {
      1: {
        title: "Die Rolle der KI im modernen Ingenieurwesen",
        summary: "Ein tiefer Einblick, wie KI die Landschaft des Ingenieurwesens verändert und Prozesse effizienter und präziser gestaltet.",
        content: "Vollständiger Artikelinhalt hier... Dies wäre ein längerer Text, der detailliert erklärt, wie KI in modernen Ingenieurpraktiken eingesetzt wird, mit Beispielen und Einblicken."
      },
      2: {
        title: "Optimierung industrieller Prozesse durch KI",
        summary: "Entdecken Sie reale Beispiele von KI-Anwendungen, die traditionelle industrielle Arbeitsabläufe revolutionieren.",
        content: "Vollständiger Artikelinhalt hier... Dies würde Fallstudien und detaillierte Erklärungen beinhalten, wie KI verschiedene industrielle Prozesse optimiert."
      },
      3: {
        title: "Die Zukunft der KI in der Wirtschaft",
        summary: "Erfahren Sie, wie KI die Zukunft der Wirtschaft gestaltet und was das für Unternehmen in verschiedenen Branchen bedeutet.",
        content: "Vollständiger Artikelinhalt hier... Dies würde aufkommende KI-Trends, potenzielle Auswirkungen auf verschiedene Sektoren und wie sich Unternehmen auf eine KI-gesteuerte Zukunft vorbereiten können, diskutieren."
      },
      'ai-game': {
        title: "Interaktives KI-generiertes Spiel",
        summary: "Erleben Sie KI in Aktion mit unserem dynamischen, eingabegesteuerten Spiel.",
        content: ""
      }
    }
  },
  // Add more languages as needed
};
