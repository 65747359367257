import React from 'react';
import { motion } from 'framer-motion';
import { FaLinkedin, FaGitlab, FaXing, FaEnvelope, FaResearchgate } from 'react-icons/fa';
import { useLanguage, translations } from './LanguageContext';

const socialIcons = {
  LinkedIn: FaLinkedin,
  GitLab: FaGitlab,
  ResearchGate: FaResearchgate,
  Xing: FaXing,
  Email: FaEnvelope
};

const Connect = () => {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <section id="connect" className="py-16 sm:py-24 bg-white text-black">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl sm:text-4xl font-light mb-4 text-center tracking-wider"
        >
          {t.connectWithMe}
        </motion.h2>
        <motion.p 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-lg sm:text-xl font-light mb-12 sm:mb-16 text-center tracking-wide max-w-2xl mx-auto"
        >
          {t.connectDesc}
        </motion.p>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="flex justify-center space-x-4 sm:space-x-8 flex-wrap"
        >
          {[
            { name: "LinkedIn", link: "https://linkedin.com/in/Alexander_Warth" },
            { name: "GitLab", link: "https://gitlab.com/CochainComplex" },
            { name: "ResearchGate", link: "https://www.researchgate.net/profile/Alexander-Warth" },
            { name: "Xing", link: "https://www.xing.com/profile/Alexander_Warth" },
            { name: "Email", link: "mailto:alexander.warth@mailbox.org" },
          ].map((social, index) => {
            const Icon = socialIcons[social.name];
            return (
              <motion.a 
                key={index} 
                href={social.link} 
                className="text-bluegrey-dark hover:text-silvergrey transition duration-300 group flex flex-col items-center mb-4"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Icon className="mb-2 w-6 h-6 sm:w-8 sm:h-8" />
                <span className="text-sm sm:text-lg group-hover:underline">{social.name}</span>
              </motion.a>
            );
          })}
        </motion.div>
      </div>
    </section>
  );
};

export default Connect;