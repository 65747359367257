import React from 'react';
import { LanguageProvider } from './components/LanguageContext';
import Header from './components/Header';
import Hero from './components/Hero';
import AIExpertise from './components/AIExpertise';
import About from './components/About';
import Experience from './components/Experience';
import Insights from './components/Insights';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AIAssistant from './components/AIAssistant';

const App = () => (
  <LanguageProvider>
    <div className="font-sans">
      <Header />
      <Hero />
      <About />
      <Experience />
      <AIExpertise />
      <Insights />
      <Contact />
      <Footer />
      <AIAssistant />
    </div>
  </LanguageProvider>
);

export default App;