import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';

const AIGame = () => {
  const [gameState, setGameState] = useState('input');
  const [userInput, setUserInput] = useState('');
  const [score, setScore] = useState(0);
  const [playerPosition, setPlayerPosition] = useState(50);
  const [obstacles, setObstacles] = useState([]);
  const [theme, setTheme] = useState('');
  const [playerChar, setPlayerChar] = useState('@');
  const [obstacleChar, setObstacleChar] = useState('#');
  const [debugLog, setDebugLog] = useState([]);

  const gameHeight = 300;
  const gameWidth = 600;
  const playerSize = 30;

  const addDebugLog = (message) => {
    setDebugLog(prevLog => [...prevLog, message]);
  };

  const handleStart = async () => {
    if (userInput.trim() === '') return;
    setGameState('loading');
    addDebugLog(`Starting game generation with input: ${userInput}`);
    
    try {
      const response = await axios.post('/generate-game-elements', { input: userInput });
      addDebugLog(`Received response: ${JSON.stringify(response.data)}`);
      
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      
      setTheme(response.data.theme || 'Default Adventure');
      setPlayerChar(response.data.player_char || '@');
      setObstacleChar(response.data.obstacle_char || '#');
      
      addDebugLog(`Theme set to: ${response.data.theme}`);
      addDebugLog(`Player character set to: ${response.data.player_char}`);
      addDebugLog(`Obstacle character set to: ${response.data.obstacle_char}`);
      
      setGameState('playing');
      setScore(0);
      setObstacles([]);
      setPlayerPosition(50);
    } catch (error) {
      console.error('Error generating game elements:', error);
      addDebugLog(`Error occurred: ${error.message}`);
      setGameState('input');
    }
  };

  const handleJump = useCallback(() => {
    if (playerPosition < gameHeight - playerSize) {
      setPlayerPosition((prev) => Math.min(prev + 60, gameHeight - playerSize));
    }
  }, [playerPosition]);

  useEffect(() => {
    if (gameState !== 'playing') return;

    const gravity = setInterval(() => {
      setPlayerPosition((prev) => Math.max(prev - 5, 0));
    }, 50);

    const obstacleGenerator = setInterval(() => {
      setObstacles((prev) => [
        ...prev,
        {
          x: gameWidth,
          y: Math.random() * (gameHeight - playerSize),
        },
      ]);
    }, 2000);

    const gameLoop = setInterval(() => {
      setObstacles((prev) => {
        const updatedObstacles = prev
          .map((obs) => ({ ...obs, x: obs.x - 5 }))
          .filter((obs) => obs.x > -playerSize);

        const collision = updatedObstacles.some(
          (obs) =>
            obs.x < playerSize &&
            obs.x + playerSize > 0 &&
            Math.abs(obs.y - playerPosition) < playerSize
        );

        if (collision) {
          setGameState('gameOver');
        } else {
          setScore((prevScore) => prevScore + 1);
        }

        return updatedObstacles;
      });
    }, 50);

    return () => {
      clearInterval(gravity);
      clearInterval(obstacleGenerator);
      clearInterval(gameLoop);
    };
  }, [gameState, playerPosition]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.code === 'Space') {
        handleJump();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleJump]);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      {gameState === 'input' && (
        <div className="mb-4">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Enter a word to generate a game theme"
            className="border p-2 mr-2"
          />
          <button onClick={handleStart} className="bg-blue-500 text-white p-2">
            Generate Game
          </button>
        </div>
      )}
      {gameState === 'loading' && <p>Generating game elements...</p>}
      {gameState === 'playing' && (
        <>
          <p className="mb-2 text-lg font-bold">{theme}</p>
          <div 
            className="relative bg-gray-200 border-2 border-gray-400 overflow-hidden font-mono text-3xl"
            style={{ width: gameWidth, height: gameHeight }}
          >
            <div
              className="absolute bg-blue-500 flex items-center justify-center"
              style={{
                width: playerSize,
                height: playerSize,
                bottom: playerPosition,
                left: 20,
              }}
            >
              {playerChar}
            </div>
            {obstacles.map((obs, index) => (
              <div
                key={index}
                className="absolute bg-red-500 flex items-center justify-center"
                style={{
                  width: playerSize,
                  height: playerSize,
                  bottom: obs.y,
                  left: obs.x,
                }}
              >
                {obstacleChar}
              </div>
            ))}
          </div>
        </>
      )}
      {gameState === 'gameOver' && (
        <div className="text-center">
          <h2 className="text-2xl mb-4">Game Over</h2>
          <p>Your score: {score}</p>
          <button onClick={() => setGameState('input')} className="bg-blue-500 text-white p-2 mt-4">
            Play Again
          </button>
        </div>
      )}
      <p className="mt-4 text-lg">Score: {score}</p>
      <p className="mt-2">Press Space to jump</p>
      
      {/* Debug Log */}
      <div className="mt-8 p-4 bg-gray-100 w-full max-w-2xl">
        <h3 className="font-bold mb-2">Debug Log:</h3>
        {debugLog.map((log, index) => (
          <p key={index} className="text-sm">{log}</p>
        ))}
      </div>
    </div>
  );
};

export default AIGame;