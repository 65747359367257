import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { useLanguage, translations } from './LanguageContext';

const Hero = () => {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <section className="bg-gradient-to-r from-black to-gray-900 text-white min-h-screen flex items-center">
      <div className="container mx-auto px-4 text-center">
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl sm:text-5xl lg:text-6xl font-light mb-6 tracking-wider leading-tight"
        >
          {t.heroTitle}
        </motion.h1>
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.8 }}
          className="text-lg sm:text-xl font-light mb-12 tracking-wide max-w-2xl mx-auto"
        >
          {t.heroSubtitle}
        </motion.p>
        <motion.a 
          href="#connect" 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.8 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="inline-flex items-center bg-silvergrey text-black px-6 sm:px-8 py-3 font-light uppercase tracking-wider hover:bg-white transition duration-300 text-sm sm:text-base"
        >
          {t.heroButton} <ArrowRight className="ml-2" size={20} />
        </motion.a>
      </div>
    </section>
  );
};

export default Hero;