import React from 'react';
import { motion } from 'framer-motion';
import { Cpu, Code, Zap } from 'lucide-react';
import { useLanguage, translations } from './LanguageContext';

const FeatureBox = ({ Icon, title, description }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="border border-silvergray p-4 sm:p-6 hover:shadow-lg transition duration-300"
  >
    <Icon className="text-bluegrey-dark mb-4" size={24} />
    <h3 className="font-light mb-4 text-lg sm:text-xl tracking-wider">{title}</h3>
    <p className="text-gray-700 font-light text-sm sm:text-base">{description}</p>
  </motion.div>
);

const About = () => {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <section id="about" className="py-16 sm:py-24 bg-white text-black">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-3xl sm:text-4xl font-light mb-12 sm:mb-16 text-center tracking-wider"
        >
          {t.aboutTitle}
        </motion.h2>
        <div className="flex flex-col lg:flex-row items-center justify-between mb-12 sm:mb-16">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="lg:w-1/3 mb-8 lg:mb-0"
          >
            <img src="/images/alexander-warth.png" alt="Alexander Warth" className="w-48 h-48 sm:w-64 sm:h-64 object-cover shadow-xl" />
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="lg:w-2/3 lg:pl-12"
          >
            <p className="text-base sm:text-lg leading-relaxed font-light mb-6 sm:mb-8">
              {t.aboutParagraph1}
            </p>
            <p className="text-base sm:text-lg leading-relaxed font-light">
              {t.aboutParagraph2}
            </p>
          </motion.div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          <FeatureBox 
            Icon={Cpu}
            title={t.aiIntegrationTitle}
            description={t.aiIntegrationDesc}
          />
          <FeatureBox 
            Icon={Code}
            title={t.workflowAutomationTitle}
            description={t.workflowAutomationDesc}
          />
          <FeatureBox 
            Icon={Zap}
            title={t.techConsultationTitle}
            description={t.techConsultationDesc}
          />
        </div>
      </div>
    </section>
  );
};

export default About;