import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import CodeAnimation from './CodeAnimation';
import AIGame from './AIGame';
import { useLanguage, translations } from './LanguageContext';

const ArticleView = ({ article, onClose }) => {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
    >
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
        className="bg-white text-black p-4 sm:p-8 rounded-lg w-full max-w-2xl max-h-[80vh] overflow-y-auto"
        style={{ borderRadius: '0' }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl sm:text-2xl font-light tracking-wider">{article.title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        {article.id === 'ai-integration' ? (
          <CodeAnimation />
        ) : article.id === 'ai-game' ? (
          <div className="game-container">
            <AIGame />
          </div>
        ) : (
          <p className="text-gray-600 font-light leading-relaxed text-sm sm:text-base">{article.content}</p>
        )}
      </motion.div>
    </motion.div>
  );
};

export default ArticleView;