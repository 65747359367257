import React from 'react';

const Section = ({ id, title, children, dark = false }) => (
  <section id={id} className={`py-24 ${dark ? 'bg-black text-white' : 'bg-white text-black'}`}>
    <div className="container mx-auto px-6">
      <h2 className="text-4xl font-light mb-16 text-center tracking-wider">{title}</h2>
      {children}
    </div>
  </section>
);

export default Section;
