import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import CodeAnimation from './CodeAnimation';
import ArticleView from './ArticleView';
import useBlogArticles from './blogArticles';
import { useLanguage, translations } from './LanguageContext';

const Insights = () => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const { language } = useLanguage();
  const t = translations[language];
  const blogArticles = useBlogArticles();

  return (
    <section id="insights" className="py-16 sm:py-24 bg-gradient-to-r from-black to-emeraldforest text-white">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-3xl sm:text-4xl font-light mb-12 sm:mb-16 text-center tracking-wider"
        >
          {t.latestInsights}
        </motion.h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-12 max-w-6xl mx-auto">
          {blogArticles.map((article, index) => (
            <motion.div 
              key={article.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              className="border border-silvergray p-6 sm:p-8 hover:bg-white/5 transition duration-300 cursor-pointer"
              onClick={() => setSelectedArticle(article)}
            >
              <h3 className="text-xl sm:text-2xl font-light mb-4 text-silvergray tracking-wider">{article.title}</h3>
              <p className="text-gray-300 mb-6 font-light text-sm sm:text-base">{article.summary}</p>
              <motion.span 
                className="inline-flex items-center text-silvergray hover:underline font-light tracking-wider text-sm sm:text-base"
                whileHover={{ x: 5 }}
              >
                {t.readMore} <ArrowRight className="ml-2" size={16} />
              </motion.span>
            </motion.div>
          ))}

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            whileHover={{ scale: 1.05 }}
            className="border border-silvergray p-6 sm:p-8 hover:bg-white/5 transition duration-300 cursor-pointer"
            onClick={() => setSelectedArticle({ title: t.aiIntegration, id: 'ai-integration' })}
          >
            <h3 className="text-xl sm:text-2xl font-light mb-4 text-silvergray tracking-wider">{t.aiIntegration}</h3>
            <CodeAnimation />
          </motion.div>
        </div>
      </div>

      <AnimatePresence>
        {selectedArticle && (
          <ArticleView
            article={selectedArticle}
            onClose={() => setSelectedArticle(null)}
          />
        )}
      </AnimatePresence>
    </section>
  );
};

export default Insights;