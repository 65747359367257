import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, AlertTriangle } from 'lucide-react';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://warth.ai/api';

const AIAssistant = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isDarkBackground, setIsDarkBackground] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;
      const scrollPercentage = (scrollPosition / (bodyHeight - windowHeight)) * 100;
      setIsDarkBackground(scrollPercentage > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      const scrollOptions = {
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth'
      };
      
      setTimeout(() => {
        chatContainerRef.current.scrollTo(scrollOptions);
      }, 100);
    }
  }, [messages]);

  useEffect(() => {
    if (isOpen && termsAccepted && !isInitialized) {
      initializeAssistant();
    }
  }, [isOpen, termsAccepted, isInitialized]);

  const initializeAssistant = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BACKEND_URL}/initialize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setMessages([{ text: data.greeting, sender: 'ai' }]);
      setIsInitialized(true);
    } catch (error) {
      console.error('Error initializing assistant:', error);
      setMessages([{ text: 'Sorry, I encountered an error while initializing. Please try again.', sender: 'ai' }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = { text: input, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await fetch(`${BACKEND_URL}/ask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: input }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const aiMessage = { text: data.response, sender: 'ai' };

      setMessages(prevMessages => [...prevMessages, aiMessage]);
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { text: 'Sorry, I encountered an error. Please try again.', sender: 'ai' };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenAssistant = () => {
    if (termsAccepted) {
      setIsOpen(true);
    } else {
      setIsOpen(true);
    }
  };

  const handleAcceptTerms = () => {
    setTermsAccepted(true);
  };

  const handleDeclineTerms = () => {
    setIsOpen(false);
    setTermsAccepted(false);
  };

  const TermsOfUse = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div className="bg-white p-6 max-w-md">
        <h2 className="text-2xl font-semibold mb-4">Terms of Use</h2>
        <p className="mb-4">
          By using this AI Concierge, you agree to the following terms:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>This service uses OpenAI's API to generate responses.</li>
          <li>Your conversations may be logged for improvement purposes by OpenAI.</li>
          <li>Do not share sensitive or personal information.</li>
          <li>The AI's responses are generated and may not always be accurate.</li>
        </ul>
        <p className="mb-4">
          For more information, please review the
          {' '}
          <a 
            href="https://openai.com/enterprise-privacy/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-bluegrey hover:text-bluegrey-dark underline"
          >
            OpenAI API Terms of Use
          </a>.
        </p>
        <div className="flex justify-between">
          <button
            onClick={handleDeclineTerms}
            className="bg-gray-300 text-black px-4 py-2"
          >
            Decline
          </button>
          <button
            onClick={handleAcceptTerms}
            className="bg-black text-white px-4 py-2"
          >
            Accept
          </button>
        </div>
      </div>
    </motion.div>
  );

  const Disclaimer = () => (
    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
      <div className="flex">
        <AlertTriangle className="flex-shrink-0 mr-2" />
        <p>
          <strong>Disclaimer:</strong> This AI Concierge uses OpenAI's API to provide information about my background. While it's designed to help, responses may not always be perfectly accurate. Please feel free to verify any important details through direct contact.
        </p>
      </div>
    </div>
  );

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <AnimatePresence>
        {!termsAccepted && isOpen && <TermsOfUse />}
      </AnimatePresence>

      {!isOpen && (
        <motion.button 
          onClick={handleOpenAssistant}
          className={`flex items-center space-x-2 px-4 py-2 shadow-lg transition-colors duration-300 ${
            isDarkBackground ? 'bg-white text-black' : 'bg-black text-white'
          }`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
          </svg>
          <span className="font-light">AI Concierge</span>
        </motion.button>
      )}
      {isOpen && termsAccepted && (
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white shadow-xl w-96 h-[32rem] flex flex-col"
        >
          <div className="bg-black text-white p-4 flex justify-between items-center">
            <h3 className="font-light">AI Concierge</h3>
            <button onClick={() => setIsOpen(false)} className="text-white hover:text-gray-300">
              <X size={24} />
            </button>
          </div>
          <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 space-y-4">
            <Disclaimer />
            {messages.map((message, index) => (
              <div key={index} className={`${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
                <span className={`inline-block p-2 ${message.sender === 'user' ? 'bg-gray-200 text-black' : 'bg-black text-white'}`}>
                  {message.text}
                </span>
              </div>
            ))}
            {isLoading && (
              <div className="text-center">
                <span className="inline-block p-2 bg-gray-100 text-gray-500">Thinking...</span>
              </div>
            )}
          </div>
          <form onSubmit={handleSubmit} className="p-4 border-t">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Ask me anything about the CV..."
              className="w-full p-2 border focus:outline-none focus:ring-2 focus:ring-bluegrey focus:border-transparent"
              disabled={isLoading}
            />
          </form>
        </motion.div>
      )}
    </div>
  );
};

export default AIAssistant;